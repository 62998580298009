:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root > .app-container {
    height: 100%;
}

/* ::-webkit-scrollbar {
    width: 0.4rem;
}
::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: "hidden";
} */

.supContainer {
    text-decoration: none;
    cursor: auto;
}

.supContainer:hover {
    text-decoration: underline;
}

sup {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: 600;
    vertical-align: top;
    top: -1;
    margin: 0px 2px;
    min-width: 14px;
    height: 14px;
    border-radius: 3px;
    background: var(--colorBrandForeground1);
    color: #fff;
    text-decoration-color: transparent;
    outline: transparent solid 1px;
    cursor: auto;
}

.navbarPersona .ms-Persona-details .ms-TooltipHost {
    cursor: pointer;
    color: white;
}

.answerText p {
    margin: 0;
}

textarea {
    max-height: none !important;
}

/* TODO: Andrew - Figure out why the theming isn't working for dark theme. */
/*
.primaryText-99 {
    color: white;
}

.secondaryText-115 {
    color: #ccc;
}
 */
