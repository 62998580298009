/* fallback, until https://github.com/CompuIves/codesandbox-client/issues/174 is resolved */
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v36/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
}

/* document-selector is a custom classname to increases the specificity of our styles. It can be anything. 
 * The idea is that it is easy to extend/override builtin styles with very little effort.
 */

.document-selector .dropdown-trigger > span:after {
    font-size: 12px;
    color: #555;
}

.document-selector .toggle {
    font: normal normal normal 18px/1 'Material Icons';
    color: #555;
    white-space: pre;
    margin-right: 4px;
}

.document-selector .toggle.collapsed::after {
    cursor: pointer;
    content: '\E5CF';
    vertical-align: middle;
}

.document-selector .toggle.expanded::after {
    cursor: pointer;
    content: '\E5CE';
    vertical-align: middle;
}

/* checkbox styles */
.document-selector .checkbox-item {
    position: relative;
    width: 1rem;
    height: 1rem;
    margin-right: 0.75rem;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: 0;
    vertical-align: middle;
}

.document-selector .checkbox-item:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    border: 2px solid #aaa;
    transition: all 0.3s ease-in-out;
}

.document-selector .checkbox-item:checked:before {
    height: 50%;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    border-top-style: none;
    border-right-style: none;
    border-color: #2196f3;
}

.document-selector input.search {
    box-sizing: border-box;
    box-shadow: none;
    border-style: none;
    /* flex: 1 1 0%; */
    margin: 0px;
    font-size: 14px;
    font-family: inherit;
    color: rgb(50, 49, 48);
    background-color: rgb(255, 255, 255);
    height: 100%;
    padding: 0px 8px 0px 9px;
    outline: 0px;
    display: block;
    min-width: 61px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: text;
    border-radius: 2px 0px 0px 2px;
    user-select: text;
    height: 32px;
    min-width: 86px;
}

.document-selector .dropdown-trigger {
    background: white;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    right: 0px;
    width: 100%;
}

.dropdown {
    width: 100%;
}
